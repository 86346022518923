
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        bankData: {
            type: Object,
            default: () => {},
        },
        qrType: {
            type: String,
            default: 'bank',
        },
    },
    data() {
        return {
            qrPreviewImage: null,
            loading: false,
        }
    },
    computed: {
        displayQRImage: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        bankData(value) {
            if (!value?.company_bank_qr_path) {
                this.displayQRImage = false
            }
        },
    },
    methods: {
        async onFetchNewImage() {
            this.loading = true
            const response = await this.$store.dispatch(
                'themes/deposit/getCompanyBankAccountQr',
                {
                    id: this.bankData?.company_bank_info?.bank_account_id,
                    type: this.type,
                }
            )
            this.loading = false
            if (response) {
                this.qrPreviewImage = response?.qrLink
            }
        },
    },
}
